import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useState} from 'react'
import iconAnyOne from '@/assets/images/icon-anyone.svg'
import {IPT} from "@/types/type.ts";
import {createImageUrl} from "@/services/awsS3Service.tsx";
import {LiaTimesSolid} from "react-icons/lia";
import {IoCheckmarkCircleOutline} from "react-icons/io5";

interface Props {
    closeModal: () => void;
    onApply: (pt: number) => void;
    ptId: number | null;
    groupPTs: IPT[]
}

interface FilterOptionParams {
    selected: boolean,
    name: string,
    avatar_url?: string | null,
    onClick: () => void
}

const FilterOption = ({selected, name, avatar_url, onClick}: FilterOptionParams) => {
    let avatar;
    if (avatar_url) {
        avatar = <img src={createImageUrl(avatar_url)} alt={name}
                      className={`w-16 h-16 rounded-full mx-auto`}/>;
    } else {
        avatar = <img src={iconAnyOne} alt={name} className={`w-16 h-16 mx-auto`}/>;
    }
    return <div className={`col-span-1 w-[98px] bg-[#eeeeee] items-center rounded-lg cursor-pointer border mb-3 py-2 hover:border-[#ec6730]
                                                                ${selected ? `border-[#ec6730]` : `border-[#a6a6a6]`}`} onClick={onClick}>
        <div className={`h-16 w-full text-center relative`}>
            {selected && <IoCheckmarkCircleOutline className={`absolute top-0 right-1 text-[#ec6730]`}/>}
            {avatar}
        </div>
        <p className="text-xs mt-2 text-center text-[#2e8889]">{name}</p>
    </div>;
};

const FilterModal = ({closeModal, onApply, ptId, groupPTs}: Props) => {
    const [ptSelected, setPtSelected] = useState<number>(ptId ?? 0)
    const options = groupPTs.map((pt: IPT) =>
        <FilterOption key={pt.id} selected={ptSelected === pt.id} name={`${pt.first_name} ${pt.last_name}`} avatar_url={pt.avatar_url}
                      onClick={() => setPtSelected(pt.id)}/>
    );
    options.unshift(<FilterOption selected={ptSelected === 0} name={"Anyone"} onClick={() => setPtSelected(0)} key={0}/>);

    const reset = () => {
        setPtSelected(0)
        onApply(0)
    };

    return (
        <>
            <button onClick={closeModal}>Close</button>
            <Transition appear show={true} as={Fragment}>
                <Dialog as="div" className="relative z-30" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/80"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-start justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full md:w-[810px] transform overflow-hidden rounded-2xl bg-[#00c1c6]
                                    py-6 md:p-6 text-left align-middle shadow-xl transition-all border border-[#ffffff1a]">
                                    <div className={`w-full px-5 md:px-0 mx-auto pb-5 min-h-[684px]`}>
                                        <div className="flex justify-between items-center">
                                            <button className="bg-[#eeeeee] border-[#a6a6a6] border w-[34px] h-[34px] flex items-center justify-center rounded-full"
                                                    onClick={closeModal}>
                                                <LiaTimesSolid className={`w-5 h-5 text-[#2e8889]`}/>
                                            </button>
                                            <p className="text-2xl font-bold">Filters</p>
                                            <button className="underline text-sm" onClick={reset}>Reset filters</button>
                                        </div>
                                        <div className="mt-10 w-full block">
                                            <p>I would like to train with:</p>
                                            <div className="mt-3 m-auto grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-7 gap-4">
                                                {options}
                                            </div>
                                        </div>
                                        <button className={`mt-8 w-full text-center py-2 rounded cursor-pointer font-bold bg-[#ec6730]`} onClick={() => onApply(ptSelected)}>
                                            Apply filters
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default FilterModal
