import {FaArrowLeft} from "react-icons/fa6";

interface BackButtonProps {
    callback: () => void,
    className?: string
}

export const BackButton = ({callback, className = ""}: BackButtonProps) => {
    return <button className={`text-white bg-[#39354A] py-3 px-4 rounded-3xl ${className}`} onClick={callback}>
        <FaArrowLeft className={'inline-block'}/>
    </button>
}
