const createImageUrl = (url: string, height = 90, width = 90) => {
    const edits = {
        bucket: import.meta.env.VITE_AWS_IMAGE_BUCKET,
        key: url,
        edits: {
            resize: {
                width: width,
                height: height,
                fit: "cover"
            }
        }
    };
    const qs = btoa(JSON.stringify(edits));
    const base = import.meta.env.VITE_AWS_IMAGE_ENDPOINT;
    return `${base}/${qs}`;
}

export {createImageUrl}