interface HeaderProps {
    title: string,
    subtitle?: string,
    children?: React.ReactNode
}

export const Header = ({title, subtitle, children}: HeaderProps) => {
    return <div className={'mb-4'}>
        <h1 className={'text-3xl text-white font-bold mb-1'}>{title}</h1>
        {subtitle && <h3>{subtitle}</h3>}
        {children}
    </div>
}
