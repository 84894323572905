import {ReactNode} from "react";

interface WrapperProps {
    children: ReactNode,
    className?: string
}

export const Wrapper = ({children, className}: WrapperProps) => {
    return <div className={`relative w-full text-white bg-[#00c1c6] ${className}`}>
        <div className={`flex flex-col justify-between md:max-w-[529px] lg:max-w-[1440px] min-h-screen mx-auto lg:px-12 px-5 pt-6 relative w-full`}>
            {children}
        </div>
    </div>;
}
