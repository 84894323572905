import ReactDOM from 'react-dom/client'
import '@/index.css'
import Homepage from "@/pages/homepage/homepage.tsx";
import reportWebVitals from "@/reportWebVitals.ts";
import mixpanel from 'mixpanel-browser';
import * as Sentry from "@sentry/react";
import {AuthWrapper} from "@/components/auth-wrapper.tsx";
import {Chat} from "@/components/chat.tsx";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        // Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    profilesSampleRate: 1.0, // Capture 100% of the profiles
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
// Near entry of your product, init Mixpanel
mixpanel.init(import.meta.env.VITE_MIX_PANEL_ID, {debug: import.meta.env.VITE_ENV !== 'production', track_pageview: true, persistence: 'localStorage'});
ReactDOM.createRoot(document.getElementById('root')!).render(
    <>
        <AuthWrapper>
            <Homepage/>
            <Chat/>
        </AuthWrapper>
    </>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
