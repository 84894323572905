import {IPTSession} from "@/types/type.ts";
import {IoCheckmarkCircleOutline} from "react-icons/io5";
import moment from "moment-timezone";

interface SessionItemProps {
    session: IPTSession;
    onClick: () => void
    selected: boolean
}

export const SessionItem = ({session, selected, onClick}: SessionItemProps) => {
    return <div
        className={`col-span-1 py-8 rounded-lg text-center bg-[#eeeeee] border-2 ${selected ? `border-[#ec6730]` : `border-[#a6a6a6]`} cursor-pointer relative hover:border-[#ec6730]`}
        onClick={onClick}>
        {selected && <IoCheckmarkCircleOutline className={`absolute top-1 left-1 text-[#ec6730]`}/>}
        <p className={`text-[#8f8c8c]`}>{moment(session.date).utc().format("HH:mm")}</p>
        <p className={`text-xs mt-1 text-[#2e8889] capitalize`}>
            {session.creator?.name}
        </p>
    </div>;
};
