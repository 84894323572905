export interface TestimonialProps {
    url?: string,
    name?: string,
    quote?: string,
    text?: string,
    className?: string
}

export const Testimonial = ({url, name, quote, text, className}: TestimonialProps) => {
    return <div className={`${className} py-5 lg:max-w-lg mx-1 flex bg-white border-2 border-[rgba(236, 103, 48, 0.25)] rounded-xl md:p-8 p-5 flex`}>
        {/*<div className={'rounded-full w-12 h-12 bg-gray-400 text-white flex content-center items-center text-3xl -mt-10'}>*/}
        {/*    <FontAwesomeIcon icon={faQuoteLeft} className={'flex-1'}/>*/}
        {/*</div>*/}
        <blockquote className={'flex flex-col'}>
            <h3 className={'text-gray-700 md:text-2xl text-xl mt-4 font-family-din-pro font-black'}><em>{quote}</em></h3>
            {text && <p className={'text-gray-500 my-4 md:text-[18px] flex-grow'}>{text}</p>}
            {name && <figcaption className={'text-gray-900 flex content-center items-center font-family-din-pro'}>
                <img className={'rounded-full border bg-white w-10 h-10'} src={url} alt={name}/>
                <span className={'ml-4'}>{name}</span>
            </figcaption>}
        </blockquote>
        {/*<div className={'rounded-full w-12 h-12 bg-gray-400 text-white flex content-center items-center text-3xl -mb-10 ml-auto'}>*/}
        {/*    <FontAwesomeIcon icon={faQuoteRight} className={'flex-1'}/>*/}
        {/*</div>*/}
    </div>
}
