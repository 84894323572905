import React from 'react'
import {EmblaOptionsType} from 'embla-carousel'
// import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import {NextButton, PrevButton, usePrevNextButtons} from './EmblaCarouselArrowButtons'
import useEmblaCarousel from 'embla-carousel-react'

type PropType = {
    slides: React.ReactNode[]
    options?: EmblaOptionsType,
    className?: string
}

const EmblaCarousel: React.FC<PropType> = (props) => {
    const {slides, options, className} = props
    const [emblaRef, emblaApi] = useEmblaCarousel(options)

    // const { selectedIndex, scrollSnaps, onDotButtonClick } =
    //     useDotButton(emblaApi)

    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    } = usePrevNextButtons(emblaApi)

    return (
        <section className={`embla ${className}`}>
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {slides.map((component, index) => (
                        <div className="embla__slide flex lg:max-w-lg lg:min-w-lg" key={index}>
                            {component}
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex py-5 justify-start">
                <div className="embla__buttons">
                    <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled}/>
                    <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled}/>
                </div>

                {/*<div className="embla__dots">*/}
                {/*    {scrollSnaps.map((_, index) => (*/}
                {/*        <DotButton*/}
                {/*            key={index}*/}
                {/*            onClick={() => onDotButtonClick(index)}*/}
                {/*            className={'embla__dot'.concat(*/}
                {/*                index === selectedIndex ? ' embla__dot--selected' : ''*/}
                {/*            )}*/}
                {/*        />*/}
                {/*    ))}*/}
                {/*</div>*/}
            </div>
        </section>
    )
}

export default EmblaCarousel
