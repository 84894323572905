import {IPTSession, ItemSession} from "@/types/type.ts";
import moment from "moment-timezone";
import {SessionItem} from "@/components/session-item.tsx";

interface SessionDateBlockProps {
    item: ItemSession,
    sessions: IPTSession[],
    onClick: (session: IPTSession) => void,
    selectedPTSession: IPTSession | null | undefined
}

export const SessionDateBlock = ({item, sessions, onClick, selectedPTSession}: SessionDateBlockProps) => {
    return <div>
        <p>
            <strong>{moment(item.date).format("dddd")}</strong>
            <span> - </span>
            <span>{moment(item.date).format("Do MMMM")}</span>
        </p>
        <hr className={`w-3 border-[#ffffff80] mt-1`}/>
        <div className={`grid grid-cols-3 gap-3 py-4`}>
            {
                sessions.map(session => <SessionItem session={session} onClick={() => onClick(session)} selected={selectedPTSession?.id === session.id} key={session.id}/>)
            }
        </div>
    </div>;
};
