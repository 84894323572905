import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage} from "@fortawesome/free-regular-svg-icons";
import {useState} from "react";
import {faClose} from "@fortawesome/free-solid-svg-icons";

export const Chat = () => {
    const [showChat, setShowChat] = useState(false);
    const toggleChat = () => {
        console.log('toggle chat');
        setShowChat(!showChat);
    };
    const className = showChat ? "sticky bottom-[6rem] left-0 right-0 h-[35rem] -mt-[35rem]" : "hidden";
    return <>
        <div className={`${className} max-w-96 ml-auto mr-3 rounded-xl border-2 border-gray-400 p-1 bg-[#f0f2f7]`} style={{zIndex: 1}}>
            <button onClick={() => setShowChat(false)} className={'text-gray-800 float-right px-2 text-lg'}>Close&nbsp;<FontAwesomeIcon icon={faClose}/></button>
            <iframe src="https://chat.move.cc/chat/2535394/" className={`w-full h-[32.5rem]`} ></iframe>
        </div>
        <div className={"sticky right-0 bottom-[1rem] flex justify-end h-16 mx-3 -mt-16 w-16 left-[100%]"} style={{zIndex: 9999}}>
            <button className={'p-3 rounded-full border-4 bg-gray-500 border-gray-400 text-white w-16 h-16 text-3xl mb-4'} onClick={toggleChat} style={{zIndex: 9999}}>
                <FontAwesomeIcon icon={faMessage}/>
            </button>
        </div>
    </>
}
