import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {ISetupIntent} from "@/types/type.ts";
import React, {useEffect} from "react";
import {FaSpinner} from "react-icons/fa6";

interface CheckoutProps {
    setupIntent: ISetupIntent,
    complete: () => void,
    returnUrl: string,
    placingOrder: boolean,
}

export const Checkout = ({setupIntent, complete, returnUrl, placingOrder}: CheckoutProps) => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => setLoading(placingOrder), [placingOrder]);

    const submitPaymentDetails = async (event: React.MouseEvent<HTMLButtonElement>) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements || !setupIntent) {
            return;
        }
        setLoading(true);

        stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: returnUrl,
            }
        })
            .then((result) => {
                if (result.error) {
                    console.error(result.error);
                    if (result.error.message) {
                        setError(result.error.message);
                    }
                } else {
                    console.log(result);
                    // place order
                    complete();
                }
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    }

    return <>
        {!placingOrder &&
            <>
                <PaymentElement
                    options={{
                        layout: {
                            type: 'tabs',
                        },
                    }}/>
                {error && <div className={'text-red-400 mt-2 font-semibold bg-red-50 p-2 rounded'}>{error}</div>}
            </>
        }
        <button
            className={`mt-5 w-full text-center text-white py-2 rounded 
                                 bg-[#ec6730] cursor-pointer font-bold uppercase`}
            disabled={loading}
            onClick={submitPaymentDetails}
        >
            {loading ?
                <FaSpinner className="animate-spin m-auto w-6 h-6"/>
                : 'Proceed to booking'}
        </button>
    </>
}
