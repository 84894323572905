import axios, {AxiosResponse, InternalAxiosRequestConfig} from 'axios';

const apiUrl = import.meta.env.VITE_API_BASE_URL ?? 'https://staging.brawn.co.uk';

const headers = {
        'Accept': 'application/json',
        'x-brawn-api-version': '2023-12-19',
        'x-brawn-source' : 'pwa'
};

// axiosPublic
const axiosPublic = axios.create({
    baseURL: `${apiUrl}`,
    headers: headers
});

axiosPublic.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => Promise.reject(error),
);

// axiosAccessToken
const axiosAccessToken = axios.create({
    baseURL: `${apiUrl}`,
    headers: headers
});

axiosAccessToken.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const accessToken = localStorage.getItem('AccessToken');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosAccessToken.interceptors.response.use(
    (response: AxiosResponse) => response.data,
    (error) => {
        console.log(error);
        // localStorage.clear()
        // window.location.href = '/';
        return Promise.reject(error)
    },
);

// axiosAuth
const axiosAuth = axios.create({
    baseURL: `${apiUrl}`,
    headers: headers
});

axiosAuth.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const accessToken = localStorage.getItem('AuthToken');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosAuth.interceptors.response.use(
    (response: AxiosResponse) => response.data,
    (error) => {
        console.log(error);
        // localStorage.clear()
        // window.location.href = '/';
        return Promise.reject(error);
    },
);



export { axiosPublic, axiosAccessToken, axiosAuth };
