import {otpLogin} from "@/apis/api.tsx";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {FaSpinner} from "react-icons/fa6";
import {IUser} from "@/types/type.ts";
import {BackButton} from "@/components/commons/back-button.tsx";

interface OtpFormProps {
    username: string,
    success: (username: string, response?: IUser) => void,
    cancel: () => void
}

export const OtpForm = ({username, success, cancel}: OtpFormProps) => {
    return <>
        <div>
            <BackButton callback={cancel}/>
        </div>
        <Formik enableReinitialize={false} initialValues={{passcode: ""}}
                onSubmit={(values, {setFieldError, setSubmitting}) => {
                    setSubmitting(true);
                    otpLogin(username, values.passcode.toString()).then((res) => {
                        if (res.status === 200) {
                            success(username, res.data.user);
                        } else {
                            setFieldError("passcode", "Invalid Passcode");
                        }
                        setSubmitting(false);
                    }).catch((e) => {
                        console.log(e);
                        setFieldError("passcode", "Invalid Passcode");
                        setSubmitting(false);
                    })
                }}>
            {({isSubmitting, errors}) => (<Form>
                <div className={`mt-8`}>
                    <p className={'font-bold text-lg'}>We've sent an email and SMS with a passcode, when you receive it, enter it below to continue.</p>
                    <p className="text-base font-bold">Passcode</p>
                    <div className={'md:flex items-center'}>
                        <div className={'md:mr-2'}>
                            <Field
                                type="number"
                                name="passcode"
                                placeholder="Passcode"
                                autoComplete="one-time-code"
                                required
                                className={`rounded-lg p-4 border focus:outline-0 bg-white md:w-80 h-12 font-sans w-full mb-2 md:mb-0 text-gray-900
                                        placeholder:text-gray-500 ${errors?.passcode ? 'border-[#FF0002]' : ''}`}
                            />
                            <ErrorMessage name="passcode">
                                {(msg) => (
                                    <span className="text-sm text-[#FF0002]">
                                                {msg}
                                            </span>
                                )}
                            </ErrorMessage>
                        </div>
                        <button
                            disabled={isSubmitting}
                            className={`text-center text-white rounded-lg 
                                     bg-[#ec6730] cursor-pointer font-bold md:w-44 w-full h-12 font-sans
                                     disabled:bg-gray-300 disabled:cursor-none`}
                            type="submit"
                        >
                            {
                                isSubmitting ? (<FaSpinner className={`animate-spin w-5 h-5 m-auto`}/>) : 'Confirm'
                            }
                        </button>
                    </div>
                </div>
            </Form>)}
        </Formik>
    </>
}
